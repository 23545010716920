$(document).ready(function () {
  //---------------------------------
  // hamburger menu
  //---------------------------------
  function hamburger() {
    const $html = $('html');
    const $burgerBtn = $('#js-hamburger-btn');

    $(window).on('resize', function () {
      if (
        window.matchMedia('(min-width: 1025px)').matches &&
        $html.hasClass('is-open--sidebar')
      ) {
        $html.removeClass('is-open--sidebar');
      }
    });

    $burgerBtn.on('touch click', function () {
      $html.toggleClass('is-open--sidebar');

      if ($(this).attr('aria-expanded') === 'false') {
        $(this).attr('aria-expanded', true);
      } else {
        $(this).attr('aria-expanded', false);
      }
    });
  }

  //---------------------------------
  // 共通header ログインモーダル
  //---------------------------------
  function authModal() {
    const target = '#js-auth-btn';
    const targetEntry = '#js-auth-entry';
    const searchBtn = '#js-search-btn';

    $(target).on('touch click', function () {
      $(this).next(targetEntry).fadeToggle();

      return false;
    });

    $(searchBtn).on('touch click', function () {
      $(target).next(targetEntry).fadeOut();

      return false;
    });

    $(document).on('touch click', function (e) {
      if (
        !$(e.target).closest(target).length &&
        !$(e.target).closest(targetEntry).length
      ) {
        $(target).next(targetEntry).fadeOut();
      }
    });
  }

  //---------------------------------
  // 共通header 詳細検索モーダル
  //---------------------------------
  function searchModal() {
    const target = '#js-search-btn';
    const targetEntry = '#js-search-entry';
    const authBtn = '#js-auth-btn';

    $(target).on('touch click', function () {
      $(this).next(targetEntry).fadeToggle();

      return false;
    });

    $(authBtn).on('touch click', function () {
      $(target).next(targetEntry).fadeOut();

      return false;
    });

    $(document).on('touch click', function (e) {
      if (
        !$(e.target).closest(target).length &&
        !$(e.target).closest(targetEntry).length
      ) {
        $(target).next(targetEntry).fadeOut();
      }
    });
  }

  //---------------------------------
  // 共通sidebar コースアコーディオン(階層)
  //---------------------------------
  function sidebarMenuAccordion() {
    const $target = $('.js-menu-list');

    $target.on('touch click', function () {
      $(this).next('.p-list').slideToggle('fast');
      $(this).closest('.p-list__item').toggleClass('is-open--list');

      return false;
    });
  }

  //---------------------------------
  // SP 〇〇さんご利用コースアコーディオン
  //---------------------------------
  function facilityAccordion() {
    const $target = $('#js-facility-list');

    $target.on('touch click', function () {
      $(this).next('.p-facility-list__content').slideToggle('fast');
      $(this).closest('.p-facility-list').toggleClass('is-open--list');
    });
  }

  //---------------------------------
  // 修了証発行モーダル
  //---------------------------------
  function issueModal() {
    const $target = $('.js-issue-btn');

    if ($target.length) {
      // console.log('js-issue-btn', $target.length);
      $target.on('touch click', function () {
        $(this).next('.p-popup--issue').fadeToggle('fast');
        $target.not($(this)).next('.p-popup--issue').fadeOut('fast');

        return false;
      });

      $(document).on('touch click', function (e) {
        if (!$(e.target).closest('.js-issue-btn').length) {
          $target.next('.p-popup--issue').fadeOut('fast');
        }
      });
    }
  }

  // --------------------------------
  // 修了証発行 絞り込み切り替え
  // --------------------------------
  function filterChange() {
    const $elem = $('.p-filter__inner');
    const $closeBtn = $('.p-filter__btn--close');
    const $openBtn = $('.p-filter__btn--open');

    if ($elem.length) {
      $closeBtn.on('touch click', function () {
        $closeBtn.css('display', 'none');
        $elem.slideUp();
        $openBtn.fadeIn();

        return false;
      });

      $openBtn.on('touch click', function () {
        $openBtn.css('display', 'none');
        $elem.slideDown();
        $closeBtn.fadeIn();

        return false;
      });
    }
  }

  //---------------------------------
  // TOPページ heading下【重要】アコーディオン
  //---------------------------------
  function cautionAccordion() {
    const $elem = $('#js-caution');
    const $target = $elem.find('.p-caution__title');

    if ($elem.length) {
      // console.log('#js-caution', $elem.length);
      $target.on('touch click', function () {
        $(this).next('.p-caution__description').slideToggle('fast');
        $(this).parent().toggleClass('is-open--caution');
      });
    }
  }

  //---------------------------------
  // 修了証 ご利用コース アコーディオン
  //---------------------------------
  function courseAccordion() {
    const $elem = $('.js-course-accordion');
    const $target = $elem.find('.p-board__header-btn');

    if ($elem.length) {
      $target.on('touch click', function () {
        $(this).parent().next('.p-board__body').slideToggle('fast');
        $(this).closest('.p-board__inner').toggleClass('is-open--board');
      });
    }
  }

  //---------------------------------
  // 修了証 発行方法について アコーディオン
  //---------------------------------
  function aboutAccordion() {
    const $elem = $('#js-about-accordion');
    const $target = $elem.find('.p-board__header');

    if ($elem.length) {
      $target.on('touch click', function () {
        $(this).next('.p-board__body').slideToggle('fast');
        $(this).parent().toggleClass('is-open--board');
      });
    }
  }

  //---------------------------------
  // 修了証 よくある質問 アコーディオン
  //---------------------------------
  function qaAccordion() {
    const $elem = $('#js-qa-accordion');
    const $target = $elem.find('.p-board__header');

    if ($elem.length) {
      $target.on('touch click', function () {
        $(this).next('.p-board__body').slideToggle('fast');
        $(this).parent().toggleClass('is-open--board');
      });
    }
  }

  //---------------------------------
  // 講義詳細 もっと見るボタン
  //---------------------------------
  function seeMore() {
    if ($('.p-comment--list').length) {
      const pc = 10;
      const sp = 8;
      const showList = 4;
      const targetList = '.p-comment--list .p-comment__area-box';
      const targetElem = '.p-comment--list .p-section__footer';
      const targetBtn = '#js-see-more-btn';
      let flag = '';

      $(window).on('load resize', function () {
        if (window.matchMedia('(max-width: 768px)').matches && flag != 'sp') {
          flag = 'sp';

          $(targetList + ':nth-child(n + ' + (sp + 1) + ')').addClass(
            'u-sp--hide'
          );
          $(targetList + ':nth-child(n + ' + (pc + 1) + ')').removeClass(
            'u-pc--hide'
          );
          $(targetList).css('display', '');
          $(targetElem).show();
        } else if (
          window.matchMedia('(min-width: 769px)').matches &&
          flag != 'pc'
        ) {
          flag = 'pc';

          $(targetList + ':nth-child(n + ' + (pc + 1) + ')').addClass(
            'u-pc--hide'
          );
          $(targetList + ':nth-child(n + ' + (sp + 1) + ')').removeClass(
            'u-sp--hide'
          );
          $(targetList).css('display', '');
          $(targetElem).show();
        }
      });

      $(targetBtn).on('touch click', function () {
        if (window.matchMedia('(max-width: 768px)').matches) {
          $(targetList + '.u-sp--hide')
            .slice(0, showList)
            .slideDown()
            .removeClass('u-sp--hide');

          if ($(targetList + '.u-sp--hide').length === 0) {
            $(targetElem).fadeOut();
          }
        } else {
          $(targetList + '.u-pc--hide')
            .slice(0, showList)
            .slideDown()
            .removeClass('u-pc--hide');

          if ($(targetList + '.u-pc--hide').length === 0) {
            $(targetElem).fadeOut();
          }
        }

        return false;
      });
    }
  }

  //---------------------------------
  // Smooth Scrolling (anchor link + go to top)
  //---------------------------------
  function smoothScrolling() {
    const $anchor = $('a[href^="#"]');

    if($anchor.length) {
      $anchor.on('touch click', function () {
        $anchor.removeClass('is-current');

        let href = $(this).attr('href');
        let target = href == '#' || href == '' ? 'html' : href;
        let gap = 20;
        let position = $(target).offset().top - gap;

        $(this).addClass('is-current');

        $('html, body').stop().animate(
          {
            scrollTop: position,
          },
          'slow'
        );
        return false;
      });
    }
  }

  //---------------------------------
  // 初期化
  //---------------------------------
  function init() {
    hamburger();
    authModal();
    issueModal();
    searchModal();
    sidebarMenuAccordion();
    facilityAccordion();
    cautionAccordion();
    courseAccordion();
    aboutAccordion();
    qaAccordion();
    filterChange();
    seeMore();
    smoothScrolling();
  }

  init();
});
