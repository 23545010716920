/*
  --------------------
  ▼header下 バナー
  .js-slide--bnr

  ▼イチオシ講義
  .js-slide--recommend

  ▼スタンダード必修
  .js-slide--standard

  ▼新着講義
  .js-slide--new

  ▼トレンドのテーマ
  .js-slide--trend-01
  .js-slide--trend-02

  ▼レギュラーコース
  .js-slide--regular

  ▼オリジナルテーマその1
  .js-slide--original01

  ▼オリジナルテーマその2
  .js-slide--original02

  ▼オリジナルテーマその3
  .js-slide--original03

  --------------------
*/
$(document).ready(function() {
  // --------------------
  // heading バナー
  // --------------------
  if($('.js-slide--bnr').length) {
    console.log('js-slide--bnr', $('.js-slide--bnr').length);
    const swiperBnr = new Swiper('.js-slide--bnr .swiper', {
      loop: true,
      loopAdditionalSlides: 1,
      slidesPerView: 2,
      spaceBetween: 5,
      speed: 600,
      watchSlidesProgress: true,

      autoplay: {
        delay: 7000,
        disableOnInteraction: false,
        waitForTransition: false,
      },

      pagination: {
        el: '.js-slide--bnr .swiper-pagination',
        clickable: true,
      },

      breakpoints: {
        769: {
          slidesPerView: 'auto',
        },
      },
    });
  }

  // --------------------
  // イチオシ講義
  // --------------------
  if ($('.js-slide--recommend').length) {
    console.log('js-slide--recommend', $('.js-slide--recommend').length);
    const swiperRecommend = new Swiper('.js-slide--recommend .swiper', {
      loop: false,
      slidesPerView: 'auto',
      spaceBetween: 30,
      speed: 600,

      navigation: {
        nextEl: '.js-slide--recommend .swiper-button-next',
        prevEl: '.js-slide--recommend .swiper-button-prev',
      },

      breakpoints: {
        375: {
          slidesPerGroup: 1,
          speed: 400,
        },
        769: {
          slidesPerGroup: 2,
        },
        1802: {
          slidesPerGroup: 3,
        },
      },
    });
  }

  // --------------------
  // スタンダード必修コース
  // --------------------
  if ($('.js-slide--standard').length) {
    console.log('js-slide--standard', $('.js-slide--standard').length);
    const swiperStandard = new Swiper('.js-slide--standard .swiper', {
      loop: false,
      slidesPerView: 'auto',
      spaceBetween: 30,
      speed: 600,

      navigation: {
        nextEl: '.js-slide--standard .swiper-button-next',
        prevEl: '.js-slide--standard .swiper-button-prev',
      },

      breakpoints: {
        375: {
          slidesPerGroup: 1,
          speed: 400,
        },
        769: {
          slidesPerGroup: 2,
        },
        1802: {
          slidesPerGroup: 3,
        },
      },
    });
  }

  // --------------------
  // 新着講義
  // --------------------
  (function () {
    if($('.js-slide--new').length) {
      console.log('js-slide--new', $('.js-slide--new').length);
      const mediaQuery = window.matchMedia('(min-width: 769px)');
      let swiperNew = null;

      const checkBreakpoint = (e) => {
        if (e.matches) {
          // 初期化
          swiperNewInit();
        } else if (swiperNew) {
          // スライダー削除
          swiperNew.destroy(false, true);
        }
      };

      const swiperNewInit = () => {
        swiperNew = new Swiper('.js-slide--new .swiper', {
          loop: false,
          slidesPerView: 'auto',
          spaceBetween: 30,
          speed: 600,

          navigation: {
            nextEl: '.js-slide--new .swiper-button-next',
            prevEl: '.js-slide--new .swiper-button-prev',
          },

          breakpoints: {
            769: {
              slidesPerGroup: 2,
            },
            1802: {
              slidesPerGroup: 3,
            },
          },
        });
      };

      mediaQuery.addEventListener('change', checkBreakpoint); // ブレークポイント通過時に実行
      checkBreakpoint(mediaQuery); // ロード時に初回実行
    }
  })();

  // --------------------
  // トレンドのテーマ
  // --------------------
  // 研究倫理

  if ($('.js-slide--trend-01').length) {
    console.log('js-slide--trend-01', $('.js-slide--trend-01').length);
    const swiperTrend01 = new Swiper('.js-slide--trend-01 .swiper', {
      loop: false,
      slidesPerView: 'auto',
      spaceBetween: 30,
      speed: 600,

      navigation: {
        nextEl: '.js-slide--trend-01 .swiper-button-next',
        prevEl: '.js-slide--trend-01 .swiper-button-prev',
      },

      breakpoints: {
        375: {
          slidesPerGroup: 1,
          speed: 400,
        },
        769: {
          slidesPerGroup: 2,
        },
        1802: {
          slidesPerGroup: 3,
        },
      },
    });
  }

  // 統計
  if($('.js-slide--trend-02').length) {
    console.log('js-slide--trend-02', $('.js-slide--trend-02').length);
    const swiperTrend02 = new Swiper('.js-slide--trend-02 .swiper', {
      loop: false,
      slidesPerView: 'auto',
      spaceBetween: 30,
      speed: 600,

      navigation: {
        nextEl: '.js-slide--trend-02 .swiper-button-next',
        prevEl: '.js-slide--trend-02 .swiper-button-prev',
      },

      breakpoints: {
        375: {
          slidesPerGroup: 1,
          speed: 400,
        },
        769: {
          slidesPerGroup: 2,
        },
        1802: {
          slidesPerGroup: 3,
        },
      },
    });
  }

  // --------------------
  // レギュラーコース
  // --------------------
  (function () {
    if ($('.js-slide--regular').length) {
      console.log('js-slide--regular', $('.js-slide--regular').length);
      const mediaQuery = window.matchMedia('(min-width: 769px)');
      let swiperRegular = null;

      const checkBreakpoint = (e) => {
        if (e.matches) {
          // 初期化
          swiperNewInit();
        } else if (swiperRegular) {
          // スライダー削除
          swiperRegular.destroy(false, true);
        }
      };

      const swiperNewInit = () => {
        swiperRegular = new Swiper('.js-slide--regular .swiper', {
          loop: false,
          slidesPerView: 'auto',
          spaceBetween: 30,
          speed: 600,

          navigation: {
            nextEl: '.js-slide--regular .swiper-button-next',
            prevEl: '.js-slide--regular .swiper-button-prev',
          },

          breakpoints: {
            769: {
              slidesPerGroup: 2,
            },
            1802: {
              slidesPerGroup: 3,
            },
          },
        });
      };

      mediaQuery.addEventListener('change', checkBreakpoint); // ブレークポイント通過時に実行
      checkBreakpoint(mediaQuery); // ロード時に初回実行
    }
  })();

  // --------------------
  // オリジナルテーマその1
  // --------------------
  if ($('.js-slide--original01').length) {
    console.log('js-slide--original01', $('.js-slide--original01').length);
    const swiperOriginal01 = new Swiper('.js-slide--original01 .swiper', {
      loop: false,
      slidesPerView: 'auto',
      spaceBetween: 30,
      speed: 600,

      navigation: {
        nextEl: '.js-slide--original01 .swiper-button-next',
        prevEl: '.js-slide--original01 .swiper-button-prev',
      },

      breakpoints: {
        375: {
          slidesPerGroup: 1,
          speed: 400,
        },
        769: {
          slidesPerGroup: 2,
        },
        1802: {
          slidesPerGroup: 3,
        },
      },
    });
  }

  // --------------------
  // オリジナルテーマその2
  // --------------------
  if($('.js-slide--original02').length) {
    console.log('js-slide--original02', $('.js-slide--original02').length);
    const swiperOriginal02 = new Swiper('.js-slide--original02 .swiper', {
      loop: false,
      slidesPerView: 'auto',
      spaceBetween: 30,
      speed: 600,

      navigation: {
        nextEl: '.js-slide--original02 .swiper-button-next',
        prevEl: '.js-slide--original02 .swiper-button-prev',
      },

      breakpoints: {
        375: {
          slidesPerGroup: 1,
          speed: 400,
        },
        769: {
          slidesPerGroup: 2,
        },
        1802: {
          slidesPerGroup: 3,
        },
      },
    });
  }

  // --------------------
  // オリジナルテーマその3
  // --------------------
  if($('.js-slide--original03').length) {
    console.log('js-slide--original03', $('.js-slide--original03').length);
    const swiperOriginal03 = new Swiper('.js-slide--original03 .swiper', {
      loop: false,
      slidesPerView: 'auto',
      spaceBetween: 30,
      speed: 600,

      navigation: {
        nextEl: '.js-slide--original03 .swiper-button-next',
        prevEl: '.js-slide--original03 .swiper-button-prev',
      },

      breakpoints: {
        375: {
          slidesPerGroup: 1,
          speed: 400,
        },
        769: {
          slidesPerGroup: 2,
        },
        1802: {
          slidesPerGroup: 3,
        },
      },
    });
  }
});
